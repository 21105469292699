@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../module/animation/animation';

$small-image-width: 152px;
$small-image-margin: 10px;
$medium-image-width: 421px;
$medium-image-margin: 25px;
$extra-large-image-width: 537px;
$extra-large-image-margin: 32px;

@keyframes move-right-small {
  from { left: -1620px; }
  to { left: -810px; }
}

@keyframes move-right-medium {
  from { left: -4460px; }
  to { left: -2230px; }
}

@keyframes move-right-extra-large {
  from { left: -5690px; }
  to { left: -2845px; }
}

@keyframes move-left-small {
  from { left: -810px; }
  to { left: -1620px; }
}

@keyframes move-left-medium {
  from { left: -2230px; }
  to { left: -4460px; }
}

@keyframes move-left-extra-large {
  from { left: -2845px; }
  to { left: -5690px; }
}

.hidden-area{
  width: 100%;
  overflow: hidden;
}

.about-section-1 {
  margin-top: 55px;
  @include fade-in-from-bottom;
  @include medium{
    margin-top: 138px;
  }
  @include extra-large{
    margin-top: 69px;
    padding: 70px 28px;
    background-image: url('../../assets/image/about-section-1/about-section-1-background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50px;
    border-width: 3px;
    border-style: solid;
    border-color: rgba($color: #50C0FF, $alpha: 0.1);
  }

  &-sub-title-row{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 51px;

    @include medium{
      margin-top: 71px;
    }
    @include extra-large{}
  }

  &-sub-title{
    margin-right: 38px;
    @include font-H3;
    color: #1D74FF;
    &:last-child{
      margin-right: 0;
    }

    @include medium{
      margin-right: 88px;
      @include font-H2;
    }
    @include extra-large{
    }
  }

  &-content{

    margin-top: 19px;
    @include font-H4;


    @include medium{
      margin-top: 71px;
      @include font-H2;
    }
    @include extra-large{
    }
  }

  &-image-flow{
    position: relative;
    height: 194px;
    margin-top: 65px;
    @include medium{
      height: 538px;
      margin-top: 71px;
    }
    @include extra-large{
      height: 688px;
    }

    &-row{
      position: absolute;
      display: flex;
      animation-duration: 24s;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-timing-function: linear;

      img{
        width: $small-image-width;
        height: 92px;
        border: 1px solid #E1E7F0;
        border-radius: $small-image-margin;
        margin-right: 10px;
        object-fit: cover;
        &:last-child{
          margin-right: 0;
        }

        @include medium{
          width: $medium-image-width;
          height: 256px;
          margin-right: $medium-image-margin;
        }
        @include extra-large{
          width: $extra-large-image-width;
          height: 328px;
          margin-right: $extra-large-image-margin;
        }
      }

      &.top{
        top: 0;
        animation-name: move-right-small;
        @include medium{
          animation-name: move-right-medium;
        }
        @include extra-large{
          animation-name: move-right-extra-large;
        }
      }

      &.bottom{
        bottom: 0;
        animation-name: move-left-small;
        @include medium{
          animation-name: move-left-medium;
        }
        @include extra-large{
          animation-name: move-left-extra-large;
        }
      }
    }
  }
}
