@import "../../styles/fonts.scss";

.support-section-2{
    margin-top:97px;
    @include medium{
    margin-top: 92px;
    }
    @include extra-large{
        margin-top: 92px;
    }
    &-container{
        width: 370px;
        @include medium{
            @include container-medium;
        }
        @include extra-large{
            @include container-extra-large
        }

        &-title{
            margin-bottom: 40px;
            @include font-H1M;
            text-align: center;
            @include medium{
                @include font-H1;
            }

        }
        &-faq{
            &-category{
                margin-bottom: 66px;
                &-item{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 39px;
                    margin-right:5px;
                    @include font-H3;
                    text-align: center;
                    color: #AFB8C6;;
                    cursor: pointer;
                    @include medium{
                        align-items: center;
                        width: 196px;
                        height: 82px;
                        @include font-H2;
                        text-align: center;
                        color: #AFB8C6;;
                        cursor: pointer;
                    }              
                    @include extra-large{
                    }
       
                    &-selected{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 39px;
                    margin-right:5px;
                    border-radius: 21px;
                    background: #F1F4FA;
                    @include font-H3;
                    text-align: center;
                    cursor: pointer;
                    @include medium{
                        width: 196px;
                        height: 82px;
                        @include font-H2;
                    }
                    @include extra-large{
                        width: 196px;
                    }
                    }
                }
                &-item:hover{
                    height: 39px;
                    border-radius: 21px;
                    background: #F1F4FA;
                    color: #000;
                    @include medium{
                        height: 82px;
                    }
                }
            }
            &-item{
                margin-bottom: 48px;
                cursor: pointer;
                &-top{
                    padding-bottom: 18px;
                    border-bottom: 1px solid #C4C4C4;

                    &-title{
                        @include font-H2;
                    }
                    &-btn{
                        width: 28px;
                        height: 28px;
                    }
                }
                &-bottom{
                    width: 100%;
                    margin-top: 18px;
                    @include font-H3;
                    transition: max-height 1s ease-out;
                    &-description{
                        width:100%
                    }
                    &-detail{
                        width: 100%;
                        margin-top: 5px;
                        word-break:break-all;
                    }
                }
            }
        }
    }
}

