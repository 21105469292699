@import '../../styles/fonts';
@import '../../module/animation/animation';

.home-section-7 {
  width: 100%;
  height: 385px;
  background-image: url("../../assets/image/home-section-7/background-small.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  @include medium{
    height: 898px;
    background-image: url("../../assets/image/home-section-7/background-medium.png");
    background-position: center;
  }
  @include extra-large{
    background-image: url("../../assets/image/home-section-7/background-extra-large.png");
  }
  .info-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    @include medium{
      align-items: center;
    }
  }
  .headline{
    @include font-H2;
    color: #21222C;
  }
  .flicker{
    margin-top: 31.05px;
    height: 70px;
    @include font-H1;
    color: #21222C;
  }
}
