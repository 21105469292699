@import '../../styles/fonts';
@import '../../module/animation/animation';

.home-section-9 {
  @include fade-in-from-bottom-paused;
  .desc{
    @include font-H2M;;
    color: #58A5FF;
    filter: drop-shadow(0px 0px 13px #81BBFF);
    text-align: center;
    margin-top: 30px;
    @include medium{
      @include font-H3;
    }
    @include extra-large{
      margin-top: 32px;
    }
  }
  .box-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 51px;
    row-gap: 24px;
    @include medium{
      margin-top: 60px;
      row-gap: 28px;
    }
    @include extra-large{
      margin-top: 100px;
      row-gap: 30px;
    }
    .box{
      flex: 370px 1 0;
      height: 345px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 35px;
      @include extra-large{
        width: 381px;
        height: 355px;
      }
      &-purple{
        background-image: url("../../assets/image/home-section-9/background-section-9-purple.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }
      &-orange{
        background-image: url("../../assets/image/home-section-9/background-section-9-orange.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }
      &-green{
        background-image: url("../../assets/image/home-section-9/background-section-9-green.svg");
        background-position: center;
        background-repeat: no-repeat;
      }
      .title{
        @include font-H1M;
        background: linear-gradient(266.17deg, #698FB2 46.86%, #F36CD1 99.96%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 19px;
        @include extra-large{
          @include font-H2;
        }
      }
      .info-container{
        margin-top: 31px;
        .info{
          @include font-H2M;
          color: #282828;
          @include extra-large{
            @include font-H3;
          }
        }
      }
      .button{
        width: fit-content;
        padding: 14px 32px;
        background: linear-gradient(95.06deg, #6B6DFC 0%, #4BA9FF 100%);
        border-radius: 40px;
        .text{
          @include font-button;
          color: #ffffff;
        }
      }
    }
  }
}
