@import "../styles/fonts";
@import "../styles/variable";

.header {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: $header-height;
  top: 0;
  align-items: center;
  z-index: 100;
  background-color: white;
  -webkit-transition: height 0.2s;
  -moz-transition: height 0.2s;
  -ms-transition: height 0.2s;
  -o-transition: height 0.2s;
  transition: height 0.2s;
  overflow: hidden;

  &-container {
    display: flex;
    height: $header-height;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    @include medium {
      padding: 0;
    }
  }

  &-menu {
    display: none;
    @include medium {
      display: initial;
    }
  }

  &-hamberger {
    @include medium {
      display: none;
    }

    button {
      cursor: pointer;
    }

    &-icon {
      width: 24px;
    }
  }

  .logo {
    height: 32px;
    object-fit: contain;
    &.mobile {
      display: initial;
      @include medium {
        display: none;
      }
    }

    &.medium {
      display: none;
      @include medium {
        display: initial;
      }
    }
  }

  .menu {
    &-text {
      @include hover-button;
      @include font-H3;
      @include medium {
        @include font-H4;
      }
    }
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }

  &-mobile-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    max-width: 390px;
    width: 100%;
    top: $header-height;
    &-row {
      display: flex;
      justify-content: center;
      padding: 20px 0;
    }
  }

  &-space {
    height: $header-height;
  }
}
