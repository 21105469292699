@import "../../styles/fonts.scss";

.download-section-1{
    margin-top: 50px;
    @include medium{
        margin-top: 63px;
        }
    @include extra-large{
        margin-top: 92px;
    }
    &-container{
        position: relative;
        padding: 35px 10px 0 10px;
        border-radius: 50px;
        @include medium{
            padding: 50px 12px 0 12px
        }
        @include extra-large{
            padding: 69px 128px 52px 103px
        }
        &-img{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            z-index:-1
        }
        &-title{
            white-space: pre-wrap;
            margin-bottom: 26px;
            @include font-H1M;
            text-align: center;
            @include medium{
                width: 580px;
                margin-bottom: 33px;
                @include font-H1;
            }
            @include extra-large{
                width: 880px;
                margin-bottom: 57px;
            }
        }
        &-select{
            width: 356px;
            height: 50px;
            padding: 3px;
            background: #F8F8F9;
            border: 1px solid #F2F2F5;
            border-radius: 10px;
            @include medium{
                width: 100%;
                height: 60px;
                padding:5px
            }
            @include extra-large{
                width: 755px;
            }
            &-btn{
                width: 100%;
                height: 100%;
                border-radius: 8px;
                @include font-H4;
                color:#9CA5B3;
                cursor: pointer;
                @include medium{
                    @include font-H2;
                }
                &-icon{
                    width: 12px;
                    margin-right: 7px;
                    margin-bottom: 3px;
                    object-fit: contain;
                    @include medium{
                        width: 20px;
                        margin-bottom: 6px;
                        margin-right: 9px;
                    }
                }
            }
        }
       &-content{
        width: 100%;
        margin-top: 43px;
        @include medium{
            margin-top: 67px;
        }
        @include extra-large{
            margin:0
        }
        &-text{
            text-align: center;
            @include font-H2M;
            @include medium{
                width: 512px;
                @include font-H2;
            }
        }
        &-download-img{
            margin-top:33px;
            width: 174px;
            height: 61px;
            cursor: pointer;
        }
        &-device{
            width: 238px;
            height: 360px;
            margin-top:51px;
            overflow: hidden;
            @include medium{
                width: 287px;
                height: 486px;
                margin-top: 67px;
            }
            @include extra-large{
                width: 325px;
                height: 694px;
            }
            &-img{
                width: 100%;
            }
        }

        &-bottom{
            width: 100%;
            padding: 0 71px 0 117px;
            @include extra-large{
                padding: 0;
            }
            &-left{
                margin-top:166px;
                @include extra-large{
                    width: 560px;
                    margin-top: 168px
                }
                &-text1{
                    margin-bottom: 20px;
                    @include font-H1;
                    background: linear-gradient(94.5deg, #5BF5FF 0%, #4D61F2 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                }
                &-text2{
                    margin-bottom: 65px;
                    @include font-H2;
                }
                &-text3{
                    margin-bottom: 65px;
                    @include font-H3;
                    color: #9CA5B3;
                }
                &-download-img{
                    width: 174px;
                    height: 61px;
                    margin-bottom: 145px;
                    cursor: pointer;
                    @include extra-large{
                        margin-bottom: 143px;
                    }
                }
            }
        }
       }
    }
}
.selected{
    background: #FFFFFF;
    color: #1D74FF;
}
