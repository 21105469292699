@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../module/animation/animation';


.about-section-2 {
  width: 100%;
  padding: 113px 10px 0;
  background-image: url('../../assets/image/about-section-2/background-gradient.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .title{
    @include fade-in-from-bottom;
  }

  @include medium{
    padding: 160px 28px 0;
  }
  @include extra-large{
    background-size: contain;
    padding: 174px #{"max(28px, calc(50% - 600px))"} 0;
  }

  &-container{
    margin: 28px auto;
    @include medium{
      position: relative;
      display: grid;
      max-width: 768px;
      margin-top: 71px;
      grid-template-columns: repeat(2, #{"calc(50% - 10px)"});
      grid-template-rows: repeat(2, 280px);
      column-gap: 20px;
      row-gap: 20px;
    }
    @include extra-large{
      max-width: 1200px;
      margin-top: 165px;
      grid-template-columns: repeat(2, #{"min(50% - 20px)"});
      grid-template-rows: repeat(2, 385px);
      column-gap: 40px;
      row-gap: 40px;
    }
  }

  &-box{
    padding: 25px;
    background: rgba($color: #FFFFFF, $alpha: 0.6);
    box-shadow: 1px 2px 37px rgba(0, 0, 0, 0.04);
    border-radius: 32px;
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0;
    }

    @include medium{
      margin-bottom: 0;
    }
    @include extra-large{
      padding: 40px;
      @include fade-in-from-bottom;
    }

    &-title{
      @include font-H2;
    }

    &-content{
      margin-top: 38px;
      @include font-H4;

      @include medium{}
      @include extra-large{
        margin-top: 59px;
        @include font-H3;
      }
    }
  }
}
