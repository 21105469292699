$small-container : 370px;
$medium-container : 768px;
$large-container : 992px;
$extra-large-container : 1200px;

$header-height: 80px;

$small-screen : 390px;
$medium-screen : 834px;
$large-screen : 992px;
$extra-large-screen : 1280px;