@import "../styles/fonts";
@import "../styles/variable";

.section-title {
  max-width: $small-container;
  width: 100%;
  margin: 0 auto;
  @include font-H1M;
  color: #21222C;
  text-align: center;
  white-space: pre-line;
  @include medium {
    max-width: $medium-container;
    @include font-H1;
  }
  @include extra-large {
    max-width: $extra-large-container;
  }
}
