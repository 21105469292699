.FLEX_ROW {
  display: flex;
  flex-direction: row;
}
.FLEX_ROW_ALIGN_CENTER {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.FLEX_COLUMN {
  display: flex;
  flex-direction: column;
}
.FLEX_COLUMN_ALIGN_CENTER {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.JUSTIFY_CENTER {
  justify-content: center;
}
.JUSTIFY_START {
  justify-content: flex-start;
}
.ALIGN_CENTER {
  align-items: center;
}
.FLEX_END {
  align-items: flex-end;
}
.FLEX_CENTER {
  display: flex;
  justify-content: center;
  align-items: center;
}
.SPACE_BETWEEN {
  justify-content: space-between;
}
.SPACE_AROUND {
  justify-content: space-around;
}
.FLEX_WRAP {
  flex-wrap: wrap;
}
.ALIGN_SELF_FLEX_START {
  align-self: flex-start;
}
