@import '../../styles/fonts';
@import '../../module/animation/animation';

$flex-gap: 20px;
.home-section-8 {
  .title{
    @include fade-in-from-bottom-paused;
  }
  .box-container{
    display: flex;
    flex-wrap: wrap;
    row-gap: 23px;
    column-gap: 23px;
    @include medium{
      row-gap: 28px;
      column-gap: 28px;
    }
    @include extra-large{
      row-gap: 20px;
      column-gap: 20px;
    }
    .box-wrapper{
      display: flex;
      flex-direction: column;
      row-gap: $flex-gap;
      &-order-1{
        order: 2;
        @include extra-large{
          order: 1;
        }
      }
      &-order-2{
        order: 1;
        @include extra-large{
          order: 2;
        }
      }
      &-order-3{
        order: 3;
      }
    }
  }
  .box{
    width: 370px;
    height: 284px;
    background: #F4F7FF;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include medium{
      height: 271px;
    }
    @include extra-large{
      width: 300px;
      height: 230px;
      &.fade-first{
        @include fade-in-from-bottom-paused;
      }
      &.fade-second{
        @include fade-in-from-bottom-paused;
      }
      &.fade-third{
        @include fade-in-from-bottom-paused;
      }
      &.fade-fourth{
        @include fade-in-from-bottom-paused;
      }
      &.fade-fifth{
        @include fade-in-from-bottom-paused;
      }
      &.fade-six{
        @include fade-in-from-bottom-paused;
      }
    }
    &-long{
      width: 370px;
      height: 385px;
      @include extra-large{
        width: 300px;
        height: 302px;
      }
    }
    &-short-wide{
      @include extra-large{
        width: 560px;
        height: 156px;
      }
    }
    &-long-wide{
      width: 370px;
      height: 248px;
      @include medium{
        width: 768px;
        height: 491px;
      }
      @include extra-large{
        width: 560px;
        height: 376px;
      }
    }
    .title{
      @include font-H1M;
      color: #000000;
      text-align: center;
      animation: unset;
      @include extra-large{
        @include font-H2;
      }
      &-large{
        @include font-H1M;
        color: #000000;
        text-align: center;
        @include medium{
          @include font-H1;
        }
      }
    }
    .desc{
      @include font-H2M;
      color: #AFB8C6;
      text-align: center;
      margin-top: 18px;
      @include extra-large{
        @include font-H4;
      }
    }
    .logo{
      &-eq-hub{
        width: 255px;
        height: 59px;
        margin-top: 58px;
        @include extra-large{
          width: 207px;
          height: 48px;
          margin-top: 38px;
        }
      }
      &-whisper{
        width: 101px;
        height: 101px;
        @include extra-large{
          width: 82px;
          height: 82px;
        }
      }
    }
    .swiper-container{
      width: 100%;
      margin-top: 52px;
      text-align: center;
      .swiper-icon{
        width: 60px;
        height: 60px;
        @include medium{
          width: 126px;
          height: 126px;
        }
        @include extra-large{
          width: 92px;
          height: 92px;
        }
      }
    }
  }
}
