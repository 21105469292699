@import "../styles/fonts";
@import "../styles/mixins";

.stores {
  a{
    display: flex;
    height: 61px;
    margin-right: 31px;
    background-color: white;
    @include hover-button;

    &:last-child{
      margin-right: 0;
    }

    img{
      width: 150px;
      height: 40px;
      object-fit: contain;
    }
  }
}
