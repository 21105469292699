@import '../../styles/fonts';
@import '../../module/animation/animation';

.home-section-6 {
  .title{
    @include fade-in-from-bottom-paused;
  }
  &-container{
    position: relative;
    display: flex;
    justify-content: center;
    width: 370px;
    margin: 0 auto;
    @include medium{
      width: 377px;
    }
    @include extra-large{
      width: 1200px;
    }
  }
  button{
    display: flex;
    flex-direction: column;
    height: 630px;
    margin-right: 11px;
    cursor: pointer;
    &:last-child{
      margin-right: 0;
    }
    @include medium{
      height: 650px;
    }
    @include extra-large{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 750px;
      margin-right: 30px;
    }
  }
  .content_0{
    @include extra-large{
      @include fade-in-from-bottom-paused;
    }
  }
  .content_1{
    @include extra-large{
      @include fade-in-from-bottom-paused;
    }
  }
  .content_2{
    @include extra-large{
      @include fade-in-from-bottom-paused;
    }
  }
  .bar{
    width: 116px;
    height: 4px;
    background-color: #AFB8C6;
    transition: background-color 0.5s;
    @include medium{}
    @include extra-large{
      width: 380px;
    }
  }
  .text{
    position: absolute;
    width: 377px;
    height: 150px;
    left: 0;
    top: 44px;
    color: #21222C;
    text-align: center;
    @include font-H2;
    opacity: 0;
    transition: opacity 0.5s;

    @include medium{}
    @include extra-large{
      width: 380px;
      opacity: 0.3;
    }
  }
  img{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.5s;
    width: 377px;
    @include medium{
      width: 377px;
    }
    @include extra-large{
      width: 380px;
      opacity: 0.3;
    }
  }
}
