@import '../../styles/fonts';
@import '../../module/animation/animation';

.home-section-1 {
  background-image: url("../../assets/image/home-section-1/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  @include fade-in-from-bottom;
  @include medium{
    padding-top: 50px;
    // background-size: contain;
  }
  @include extra-large{
    background-size: cover;
    background-position: center;
    // height: 842.55px;
    padding: 86px 0;
  }

  &-container {
    display: flex;
    flex-direction: column-reverse;
    width: 370px;
    height: 758px;
    padding: 40px 0 45px;
    box-shadow: 1px 2px 37px rgba(0, 0, 0, 0.0443346);
    border-radius: 44px;

    @include medium{
      width: 768px;
      height: 804px;
    };
    @include extra-large{
      width: 1200px;
      height: 730px;
      flex-direction: row;
    };

    &-image-container {
      position: relative;
      flex: 1;
      margin-top: 60px;
      @include extra-large{
        margin-top: 0;
      }
      &-image-1 {
        width: 193.77px;
        height: 387.24px;
        position: absolute;
        left: 66.5px;
        @include medium{
          left: 265.54px;
        }
        @include extra-large{
          @include transform-translate-50;
          width: 252px;
          height: 514px;
          top: 76px;
          left: 113px;
        }
      }

      &-image-2 {
        width: 181.96px;
        height: 228.16px;
        position: absolute;
        left: 168px;
        top: 68.51px;
        @include medium{
          left: 367.04px;
        }
        @include extra-large{
          @include transform-translate-30;
          width: 236px;
          height: 295px;
          top: 165px;
          left: 245px;
        }
      }

      &-image-3 {
        width: 131.16px;
        height: 149.27px;
        position: absolute;
        top: 296.67px;
        left: 20px;
        @include medium{
          left: 219px;
        }
        @include extra-large{
          @include transform-translate-25;
          width: 174px;
          height: 193px;
          top: 465px;
          left: 53px;
        }
      }

      &-image-4 {
        width: 186.08px;
        height: 41px;
        position: absolute;
        left: 168px;
        top: 316px;
        @include medium{
          left: 367.04px;
        }
        @include extra-large{
          @include transform-translate-12;
          width: 236px;
          height: 52px;
          top: 474px;
          left: 241px;
        }
      }
    }

    &-info-container {
      text-align: center;
      // margin-top: 40px;
      @include extra-large{
        margin-top: 0;
        flex: 1.2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        @include font-H1;
      }

      &-title {
        background: linear-gradient(94.5deg, #5BF5FF 0%, #4D61F2 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include font-H1M;
        @include medium{
          @include font-H1;
        }
        @include extra-large{
          @include font-H1;
        }
      }

      &-desc {
        color: #000000;
        margin-top: 28px;
        @include font-H2M;
        @include medium{
          margin-top: 30px;
          @include font-H2;
        }
        @include extra-large{
          margin-top: 20px;
          @include font-H2;
        }
      }
      .store{
        display: none;
        @include extra-large{
          display: block;
        }
      }
    }
  }

  &-stores{
    display: none;
    @include extra-large{
      display: initial;
    }
  }
}
