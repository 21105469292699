@import 'mixins';

@mixin font-face(
  $font-name,
  $file-name,
  $weight: normal,
  $style: normal,
) {
  @font-face {
    font-family: '#{$font-name}';
    src: url('../assets/fonts/#{$file-name}.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
  }
}

@include font-face('Axiforma', 'Axiforma-Book', 100, normal);
@include font-face('Axiforma', 'Axiforma-Thin', 200, normal);
@include font-face('Axiforma', 'Axiforma-Light', 300, normal);
@include font-face('Axiforma', 'Axiforma-Medium', 400, normal);
@include font-face('Axiforma', 'Axiforma-Regular', 500, normal);
@include font-face('Axiforma', 'Axiforma-SemiBold', 600, normal);
@include font-face('Axiforma', 'Axiforma-Bold', 700, normal);
@include font-face('Axiforma', 'Axiforma-ExtraBold', 800, normal);
@include font-face('Axiforma', 'Axiforma-Heavy', 900, normal);

@mixin font-size($font-size) {
  font-size: $font-size;
}

@mixin line-height($line-height) {
  line-height: $line-height;
}

@mixin font-H1 {
  font-family: Axiforma, sans-serif;
  font-weight: 700;
  @include font-size(56px);
  @include line-height(70px)
}
@mixin font-H2 {
  font-family: Axiforma, sans-serif;
  font-weight: 600;
  @include font-size(24px);
  @include line-height(32px)
}
@mixin font-H3 {
  font-family: Axiforma, sans-serif;
  font-weight: 400;
  @include font-size(18px);
  @include line-height(29px)
}
@mixin font-H4 {
  font-family: Axiforma, sans-serif;
  font-weight: 400;
  @include font-size(14px);
  @include line-height(22px)
}
@mixin font-H5 {
  font-family: Axiforma, sans-serif;
  font-weight: 400;
  @include font-size(12px);
  @include line-height(19px)
}
@mixin font-button {
  font-family: Axiforma, sans-serif;
  font-weight: 600;
  @include font-size(16px);
  @include line-height(20px)
}
@mixin font-H1M {
  font-family: Axiforma, sans-serif;
  font-weight: 700;
  @include font-size(32px);
  @include line-height(46px)
}
@mixin font-H2M {
  font-family: Axiforma, sans-serif;
  font-weight: 600;
  @include font-size(16px);
  @include line-height(26px)
}
