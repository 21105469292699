@import '../../styles/fonts';
@import '../../module/animation/animation';

.home-section-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  @include fade-in-from-bottom;
  &-youtube-container{
    box-shadow: 0px 0px 24px 2px #BBD6FF;
    border-radius: 24px;
    overflow: hidden;
  }
}
