@import '../../styles/fonts';
@import '../../module/animation/animation';

.home-section-5 {
  .title-container{
    .title{
      background: linear-gradient(90deg, #6A72FD 0%, #4DA6FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      @include fade-in-from-bottom-paused;
      @include font-H2;
      @include medium{
        @include font-H1M;
      }
      @include extra-large{
        text-align: left;
        @include font-H2;
      }
    }
  }
  .chain-icon-container{
    display: flex;
    column-gap: 36px;
    margin-top: 30px;
    @include fade-in-from-bottom-paused;
    @include medium{
      margin-top: 88px;
      justify-content: center;
    }
    @include extra-large{
      margin-top: 102px;
      justify-content: flex-start;
    }
    .chain-icon{
      width: 80px;
      height: 80px;
      @include medium{
        width: 100px;
        height: 100px;
      }
      @include extra-large{
        width: 100px;
        height: 100px;
      }
    }
  }
  .word-flicker-container{
    height: 220px;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(215, 231, 249, 0.473958) 50.52%, rgba(217, 217, 217, 0) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    #word{
      @include font-H2;
      color: #21222C;
      @include medium{
        @include font-H1M;
      }
      @include extra-large{
        height: 140px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include font-H1;
      }
    }
    .word{
      @include fade-in-from-bottom-paused;
    }
  }
}
