@import '../../styles/fonts';
@import '../../module/animation/animation';

.home-section-3 {
  position: relative;
  width: 100%;
  background-image: url("../../assets/image/home-section-3/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // padding: 50px 0 0;
  @include medium{
    // padding: 220px 0 0;
    background-size: contain;
  }
  @include extra-large{
    // padding: 280px 0 0;
  }
  .section-title{
    @include fade-in-from-bottom-paused;
  }
  .flex-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 50px;
    row-gap: 50px;
    @include medium{
      flex-direction: row;
      column-gap: 25px;
      row-gap: 25px;
    }
    @include extra-large{
      column-gap: 40px;
      row-gap: 40px;
    }
  }
  .box{
    border-radius: 32px;
    width: 370px;
    height: 385px;
    padding: 40px 22px;
    @include medium{
      width: 768px;
      height: 246px;
      padding: 20px;
    }
    @include extra-large{
      width: 1200px;
      height: 385px;
      padding: 40px;
    }
    &-half{
      width: 370px;
      height: 302px;
      border-radius: 32px;
      padding: 40px 22px;
      @include medium{
        width: 371px;
        height: 246px;
        padding: 20px;
      }
      @include extra-large{
        width: 580px;
        height: 385px;
        padding: 40px;
      }
    }
    &-text{
      @include font-H2;
      color: #000000;
      @include medium{
        @include font-H2M;
      }
      @include extra-large{
        @include font-H2;
      }
    }
    &-first{
      background-image: url("../../assets/image/home-section-3/background-1-small.png");
      background-size: contain;
      background-repeat: no-repeat;
      @include fade-in-from-bottom-paused;
      @include medium{
        background-image: url("../../assets/image/home-section-3/background-1-extra-large.png");
      }
    }
    &-second{
      background-image: url("../../assets/image/home-section-3/background-2-small.png");
      background-size: contain;
      background-repeat: no-repeat;
      @include fade-in-from-bottom-paused;
      @include medium{
        background-image: url("../../assets/image/home-section-3/background-2-extra-large.png");
      }
    }
    &-third{
      background-image: url("../../assets/image/home-section-3/background-3-small.png");
      background-size: contain;
      background-repeat: no-repeat;
      @include fade-in-from-bottom-paused;
      @include medium{
        background-image: url("../../assets/image/home-section-3/background-3-extra-large.png");
      }
    }
  }
}
