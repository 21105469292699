@import '../../styles/fonts';
@import '../../module/animation/animation';

.home-section-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  .section-title{
    @include fade-in-from-bottom-paused;
  }
  .example-container{
    background-image: url("../../assets/image/home-section-4/background-1-small.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 73px;
    @include fade-in-from-bottom-paused;
    @include medium{
      background-image: url("../../assets/image/home-section-4/background-1-medium.svg");
      margin-top: 32px;
    }
    @include extra-large{
      background-image: url("../../assets/image/home-section-4/background-1-extra-large.svg");
      margin-top: 156px;
    }
    .example-title-container{
      display: flex;
      width: 370px;
      height: 485px;
      padding: 30px;
      @include medium{
        width: 768px;
        height: 926px;
        padding: 62px;
      }
      @include extra-large{
        width: 1200px;
        height: 700px;
        padding: 42px 53px 0 53px;
      }
      .dia-image{
        display: none;
        @include extra-large{
          display: block;
          width: 64px;
          height: 64px;
          margin-right: 12px;
        }
      }
      .title-1{
        @include font-H4;
        color: #AFB8C6;
        @include medium{
          @include font-H2;
        }
      }
      .title-2{
        @include font-H2;
        color: #000000;
        @include medium{
          @include font-H1M;
        }
        @include extra-large{
          @include font-H2;
        }
      }
    }
  }
  .button-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 36px;
    margin-top: 50px;
    @include medium{
      margin-top: 36px;
      column-gap: 28px;
    }
    @include extra-large{
      margin-top: 68px;
      column-gap: 30px;
    }
    .button{
      width: 370px;
      height: 190px;
      border-radius: 32px;
      color: #ffffff;
      padding: 25px;
      @include extra-large{
        width: 585px;
        height: 300px;
        padding: 40px;
        @include font-H2;
      }
      &-support-nft{
        background-image: url("../../assets/image/home-section-4/background-2.svg");
        background-size: contain;
        background-repeat: no-repeat;
        @include fade-in-from-bottom-paused;
      }
      &-connect-dApp{
        background-image: url("../../assets/image/home-section-4/background-3.svg");
        background-size: contain;
        background-repeat: no-repeat;
        @include fade-in-from-bottom-paused;
      }
    }
  }
}
