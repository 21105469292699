@import '../styles/fonts';
@import '../module/animation/animation';

.footer {
  margin-top: 97px;
  margin-bottom: 60px;
  @include small{
    margin-top: 97px;
  }
  @include medium{
    margin-top: 150px; 
  }
  @include extra-large{
    margin-top: 324px; 
  }
  &-container{
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    @include medium{

    }
    @include extra-large{
      flex-direction: row;
      justify-content: space-between;
    }

  }
  &-section {
    width: 200px;
    &-title{
      @include font-H2;
      margin-bottom: 40px;
    }
    &-list{
      &-item{
        margin-bottom: 40px;
        &-link{
          @include font-H3;;
          color: #4F6084;
        }
      }
    }
    &.section-mobile{
      display: initial;
      margin-top: 40px;
      @include medium{
        display: none;
      }
    }
    &.section-medium{
      display: none;
      @include medium{
        display: initial;
      }
    }
  }
  &-social{
    margin-top: 50px;
    @include extra-large{
      margin-top: 0;
    }
    &-icon{
      height: 20px;
       margin-right: 40px;
      :last-child{
        margin-right: 0;
      }
    }
  }
  &-copyright{
    padding-top: 50px;
    @include font-H4;
    color: #4F6084;
    @include medium{}
    @include extra-large{
      margin-top: 150px;
    }
  }
}
