@import "../../styles/fonts.scss";

.support-section-1 {
  margin-top: 50px;
  @include medium {
    margin-top: 63px;
  }
  @include extra-large {
    margin-top: 92px;
  }
  &-container {
    position: relative;
    width: 370px;
    padding: 20px;
    border-radius: 50px;
    background-image: url("../../assets/image/support-section-1/section-1-background-large.svg");
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid #e5e5e5;
    @include medium {
      width: 768px;
      padding: 50px;
    }
    @include extra-large {
      width: 1200px;
      padding: 50px;
    }

    &-subtitle {
      white-space: pre-wrap;
      margin-bottom: 28px;
      @include font-H2M;
      text-align: center;
      @include medium {
        margin-bottom: 22px;
      }
      @include extra-large {
        margin-bottom: 40px;
        @include font-H3;
      }
    }

    &-title {
      white-space: pre-wrap;
      margin-bottom: 40px;
      @include font-H1M;
      text-align: center;
      @include medium {
        @include font-H2;
      }
      @include extra-large {
        margin-bottom: 60px;
        @include font-H1;
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 52px;
      background: linear-gradient(90deg, #6b6dfc 0%, #4ba9ff 100%);
      border-radius: 40px;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
      @include medium {
        border-radius: 8px;
      }
      @include extra-large {
        width: 738px;
      }
    }
  }
}
