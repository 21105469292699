@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../module/animation/animation';


.about-section-3 {
  width: 100%;
  padding-top: 84px;
  @include fade-in-from-bottom-paused;

  @include medium{
    padding-top: 208px;
  }
  @include extra-large{
    padding-top: 174px;
  }
  @include fade-in-from-bottom-paused;

  &-container{
    display: grid;
    width: fit-content;
    margin: 61px auto 0;
    grid-template-columns: repeat(2, 115px);
    grid-template-rows: repeat(4, 115px);
    column-gap: 60px;
    row-gap: 60px;
    @include medium{
      margin-top: 119px;
      grid-template-columns: repeat(4, 115px);
      grid-template-rows: repeat(2, 115px);
      column-gap: 90px;
      row-gap: 90px;
    }
    @include extra-large{
      grid-template-columns: repeat(6, 115px);
      grid-template-rows: repeat(2, 115px);
    }
  }

  &-circle-wrapper{
    position: relative;
  }

  &-circle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 115px;
    border-radius: 57.5px;
    border: 1px solid #BEC8DB;
    overflow: hidden;
    cursor: pointer;
    transition: border 0.2s;

    &:hover{
      border: 3px solid rgb(29, 116, 255);
    }

    &-image{
      width: 115px;
      height: 115px;
      border-radius: 56.5px;
      object-fit: contain;
    }
  }

  &-selected-contributor{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    // top: 115px;
    left: calc(50% - 150px);
    // right: calc(50% - 150px);
    padding: 52px 35px 37px;
    background: rgba(253, 253, 253, 0.9);
    border: solid 1px rgba(240, 240, 240, 0.5);
    border-radius: 20px;
    z-index: 10;
    // opacity: 0;
    // transition: opacity 2s ease-out; /* It slowly shows the element as we place the mouse on the box and when the time reaches 2 seconds, it is fully visible. */
    // -webkit-transition: opacity 2s ease-out;
    // -moz-transition: opacity 2s ease-in-out;
    // cursor: initial;

    &-image{
      width: 120px;
      height: 120px;
      margin-right: -40px;
      border: 2px solid #B7BECA;
      border-radius: 60px;
      object-fit: cover;
    }

    &-qr-container{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 120px;
      border-radius: 12px;
      overflow: hidden;
      background-color: #1D74FF;
    }

    &-qr-icon{
      position: absolute;
      width: 35px;
      height: 35px;
    }

    &-name{
      margin-top: 28px;
      @include font-H2;
    }

    &-address{
      margin-top: 20px;
      @include font-H3;
    }

    &-button{
      margin-top: 24px;
      padding: 14px 32px;
      background: linear-gradient(95.06deg, #6B6DFC 0%, #4BA9FF 100%);
      border-radius: 40px;
      @include font-button;
      color: white;
      cursor: pointer;
    }
  }
}
