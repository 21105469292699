@import '../../styles/fonts';
@import '../../module/animation/animation';

$slider-navigation-icon-width: 18px;
$slider-navigation-margin: 60px;
.home-section-10 {
  @include fade-in-from-bottom-paused;
  .desc {
    @include font-H3;;
    color: #58A5FF;
    filter: drop-shadow(0px 0px 13px #81BBFF);
    text-align: center;
    margin-top: 34px;
  }

  .pres-release-swiper {
    flex-shrink: 0;
    display: flex;
    position: relative;
  }

  .swiper-arrow-wrapper{
    flex:1;
    display: flex;
    align-items: center;
    &-left{
      justify-content: flex-end;
    }
    &-right{
      justify-content: flex-start;
    }
    .swiper-arrow {
      width: $slider-navigation-icon-width + 120px;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .icon{
        width: $slider-navigation-icon-width;
        height: 31px;
      }
    }
  }

  .press-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 46px;
    column-gap: 20px;
    margin-top: 58px;
    @include medium{
      margin-top: 70px;
    }
  }
  .press-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    .image{
      width: 278px;
      height: 154px;
      border-radius: 10px;
      object-fit: fill;
      @include medium{
        width: 374px;
        height: 203px;
      }
      @include extra-large{
        width: 278px;
        height: 154px;
      }
    }
    .title{
      width: 278px;
      height: 58px;
      overflow:hidden;
      text-overflow:ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* 라인수 */
      -webkit-box-orient: vertical;
      word-wrap:break-word;
      @include font-H3;;
      color: #21222C;
      text-align: center;
      margin-top: 26px;
    }
  }
}
