@import "./animationKeyframes";

@mixin transform-translate-50 {
  transform: translate(calc((var(--mouse-x, 0) - 0.5) * 50px), 0)
}
@mixin transform-translate-30 {
  transform: translate(calc((var(--mouse-x, 0) - 0.5) * 30px), 0)
}
@mixin transform-translate-25 {
  transform: translate(calc((var(--mouse-x, 0) - 0.5) * 25px), 0)
}
@mixin transform-translate-12 {
  transform: translate(calc((var(--mouse-x, 0) - 0.5) * 12px), 0)
}

@mixin fade-in-from-bottom-paused () {
  animation-name: fade-in, slide-from-bottom;
  animation-duration: 200ms, 300ms;
  animation-timing-function: ease-in, ease-in;
  animation-play-state: paused;
}

@mixin fade-in-from-bottom ($delay: 0s) {
  animation-name: fade-in, slide-from-bottom;
  animation-duration: 200ms, 300ms;
  animation-timing-function: ease-in, ease-in;
  animation-delay: $delay;
}
